export const staff = [
  {
    'name': 'Madotsuki',
    'title': 'The Mod Guy',
    'url': 'https://twitter.com/MagusDevon',
    'image': '/images/sweetbread/staff/madotsuki.png',
  },
  {
    'name': 'sweetbread',
    'title': 'The Art Girl',
    'url': 'https://twitter.com/sweetbread_eth',
    'image': '/images/sweetbread/staff/bread.png',
  },
  {
    'name': 'accell',
    'title': 'The Dev Guy',
    'url': 'https://twitter.com/accellz0r',
    'image': '/images/sweetbread/staff/accell.png',
  },
];
